<template>
  <div>
    <div v-if="account.id === null">
      <v-text-field
        v-model="email"
        outlined
        label="Email"
        placeholder="john@example.com"
        hide-details
        class="mb-3"
      ></v-text-field>
      <v-btn block color="primary" class="mt-6" @click="sendEmailLostPassword(email)"> changer mon mot de passe </v-btn>
    </div>
    <div v-else>
      <v-text-field
        type="password"
        v-model="password"
        outlined
        label="Nouveau mot de passe"
        hide-details
        class="mb-3"
      ></v-text-field>
      <v-text-field
        type="password"
        v-model="passwordConfirm"
        outlined
        label="Confirmer le nouveau mot de passe"
        hide-details
        class="mb-3"
      ></v-text-field>

      <v-btn block color="primary" class="mt-6" @click="putPassword()"> changer mon mot de passe </v-btn>
    </div>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
import config from "@/views/config/config"

export default {
  name: "LostPassword",

  props: ["email"],
  data: () => ({
    account: {
      id: null,
      token: null,
    },
    password: null,
    passwordConfirm: null,
  }),
  created() {
    const urlParams = new URLSearchParams(window.location.search)
    this.account.id = urlParams.get("id")
    this.account.token = urlParams.get("token")
  },

  methods: {
    async putPassword() {
      if (this.password !== this.passwordConfirm) {
        this.$toast.error("Les mots de passe ne sont pas identiques")
      } else {
        let response
        try {
          response = await fetch(
            `${config.apiUri}/accounts?changePassword=true&token=${this.account.token}&newPassword=${this.password}&id=${this.account.id}`,
            {
              method: "PUT",
            },
          )

          const data = await response.json()
          console.log(data)

          if (data.code === 3) {
            //remove params from url
            window.history.replaceState({}, document.title, "/auth/login")
            this.$toast.success("Votre mot de passe a bien été modifié")
            window.location.reload()
          }
        } catch (err) {
          console.log(err)
        }
      }
    },
    sendmail(email, id, token) {
      try {
        emailjs.send("service_5p49t7p", "template_v6w11ri", {
          to_name: "test",
          //from_name: email,
          to_email: email,
          link: `${config.appUri}/auth/login?id=${id}&token=${token}`,
        })
      } catch (error) {
        console.log(error)
      }
    },

    async sendEmailLostPassword(email) {
      let response
      try {
        response = await fetch(`${config.apiUri}/php/pages/accounts.php?email=${email}`, {
          method: "GET",
        })

        const data = await response.json()
        console.log(data)

        if (data.code === 1) {
          this.$toast.success("Vous allez recevoir un email pour changer votre mot de passe d'ici quelques instants.")
          this.sendmail(data.account.email, data.account.id, data.account.token)
        } else {
          this.$toast.error("Cet email n'est lié à aucun compte. Veuillez réessayer.")
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
